import { useRef, useState } from "react"
import tw from "twin.macro"

import { LanguageQuoteProps } from "components/dynamic"
import Section from "components/Section"
import { BodyDetail, H5, IntroBody } from "styles/Text"
import AudioIcon from "icons/Audio"
import useLFL from "lib/useLFL"

const FeatureWord = ({
  BunubaPlaceholder,
  EnglishPlaceholder,
  LFLID,
  ...props
}) => {
  const { replacedText, ...LFLData } = useLFL(LFLID)
  const audio = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const handleClick = () => {
    if (!audio.current) {
      return
    }
    if (!isPlaying) {
      audio.current.play()
    } else {
      audio.current.pause()
      audio.current.currentTime = 0
      setIsPlaying(false)
    }
  }

  return (
    <button
      type="button"
      tw="bg-primary p-5 flex rounded-2xl my-2.5 items-center w-52 text-left cursor-pointer shadow-lg"
      onClick={handleClick}
      title={LFLData?.audio ? `Click to hear "${LFLData.markedup_text}"` : null}
      disabled={!LFLData?.audio}
      {...props}
    >
      <div
        tw="flex-grow capitalize"
        css={{
          fontSize: "22px",
          lineHeight: 1.2,
          letterSpacing: `${-1 / 22}em`,
        }}
      >
        <strong tw="block" lang="bck">
          {replacedText || LFLData?.markedup_text || BunubaPlaceholder}
        </strong>
        <div>{LFLData?.english || EnglishPlaceholder}</div>
      </div>
      <div tw="flex-shrink-0 ml-4">
        {LFLData?.audio && (
          <>
            <div
              tw="w-6 h-6 rounded-full flex items-center justify-center"
              css={isPlaying ? tw`bg-black text-primary` : null}
            >
              <AudioIcon />
            </div>
            <audio
              id={`audio-player-${LFLID}`}
              ref={audio}
              onPlay={() => setIsPlaying(true)}
              onEnded={() => setIsPlaying(false)}
            >
              <source src={LFLData.audio} />
              <track
                kind="captions"
                lang="bck"
                src={`/api/words/${LFLID}/bck.vtt`}
              />
            </audio>
          </>
        )}
      </div>
    </button>
  )
}

const LanguageQuote = ({
  Quote,
  Speaker,
  SpeakerPosition,
  Words = [],
}: LanguageQuoteProps) => (
  <Section>
    <div tw="bg-concrete rounded-2xl flex flex-col justify-center items-center p-6 md:flex-row lg:(p-7.5 pl-0)">
      {Words.length > 0 && (
        <div tw="flex-shrink-0 flex-grow -mt-16 w-72 md:(-ml-12 -my-2.5 mt-0 flex-grow-0)">
          {Words.map((word, idx) => (
            <FeatureWord
              {...word}
              key={word.LFLID}
              css={idx % 2 === 1 ? tw`ml-20` : ""}
            />
          ))}
        </div>
      )}
      <div tw="text-center flex-grow">
        <blockquote tw="m-0">
          <IntroBody tw="mt-6 mb-5">{Quote}</IntroBody>
          {Speaker && (
            <cite tw="not-italic md:w-2/3 mx-auto block">
              <H5 as="div">{Speaker}</H5>
              <BodyDetail as="div">{SpeakerPosition}</BodyDetail>
            </cite>
          )}
        </blockquote>
      </div>
    </div>
  </Section>
)

export default LanguageQuote
